import React from "react"
import Seo from "../core/Seo"
import PressLayout from "../press/Page"
import { ThemeProvider } from "styled-components"



const PressPage = () => {

  return (
    <ThemeProvider theme={{ colors: { accent: "#3790E2" } }}>
        <Seo title="We Sparkle • Simple Tools for Solopreneurs" includeZohoTracking />
        <PressLayout />
    </ThemeProvider>
  )
}

export default PressPage