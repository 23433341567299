import React from "react"
import styled from "styled-components"
import { segmentAction } from "../hooks/useSegmentTrack"
import PressList from "./PressList"

const PressItem = styled.div`
  border-bottom: 1px solid #ced3db;
  padding: 1.5rem 0;

  &:last-child {
    border: none;
  }

  a {
    color: white;
    transition: 0.2s;

    &:hover {
      color: var(--sparkle-red);
    }
  }
`
const PagePress = () => {
  return (
    <>
      <h1 style={{ marginBottom: "4rem" }}>Press & Publicity</h1>
      {PressList.map((item, i) => {
        return (
          <PressItem key={i}>
            <p style={{ fontSize: "0.85rem", marginBottom: "0.5rem" }}>
              {item.publication} — {item.date}
            </p>
            <a
              href={item.url}
              target="_blank"
              rel={"noopener noreferrer"}
              onClick={() => {
                segmentAction.track(`Press Item Click`, {
                  value: item.headline,
                })
              }}
            >
              <strong>{item.headline}</strong>
            </a>
          </PressItem>
        )
      })}
    </>
  )
}

export default PagePress
