import React from "react"
import styled, { ThemeProvider } from "styled-components"
import GlobalStyles from "../homepage/GlobalStyles"
import "../homepage/layout.css"
import PageNav from "../homepage/PageNav"
import PagePress from "./PagePress"
import PageContact from "../homepage/PageContact"

const Section = styled.section`
  :before,
  :after {
    content: "";
    clear: both;
    display: table;
  }
  line-height: 1.3;
  font-size: 1.25rem;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: bold;
  }
  h1 {
    font-size: 4.5rem;
    line-height: 0.95;
    margin-bottom: 0.5em;
  }
  @media screen and (max-width: 900px) {
    font-size: 1rem;
    line-height: 1.4;
    h1 {
      font-size: 2.25rem;
      line-height: 0.95;
      margin-bottom: 0.5em;
    }
  }
  ${({ dark }) => {
    if (dark)
      return `
        background: var(--sparkle-drkblue);
        color: #fff;
    `
  }}
`
const Content = styled.div`
  padding: 8rem var(--content-pad);
  max-width: 900px;
  margin: auto;
  @media screen and (max-width: 900px) {
    padding: 3rem var(--content-pad);
  }
`

const Page = () => {
  return (
    <ThemeProvider theme={{ color: { accent: "#3790E2" } }}>
      <GlobalStyles />
      <PageNav
        links={[
          { name: "Features", href: "/pricing#features" },
          { name: "Pricing", href: "/pricing#top" },
          { name: "Our Story", href: "/about" },
          { name: "Contact", href: "/contact" },
        ]}
      />
      <Section dark>
        <Content>
          <PagePress />
        </Content>
      </Section>
      <Section dark id={"follow-for-more"} as={"footer"}>
        <Content style={{ padding: "var(--content-pad)" }}>
          <PageContact />
        </Content>
      </Section>
    </ThemeProvider>
  )
}

export default Page
